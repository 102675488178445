import MetaData from 'feature/MetaData';
import styles from './default.module.css';

const DefaultLayout = ({ metaData, children }) => {
  return (
    <>
      <MetaData {...metaData} />

      <main className={styles.main}>
        {children}
      </main>
    </>
  );
};

export default DefaultLayout;
import { useRouter } from 'next/router';
import { useMyTeams } from 'feature/Team';
import DefaultLayout from 'project/templates/DefaultLayout';
import { Card, TopSection, Loader } from 'project/molecules';

const AppHome = ({ metaData }) => {
    const router = useRouter();
    const { teams, isLoading, isError } = useMyTeams();

    if (isLoading) return <Loader />;
    if (isError) {
        router.push('/error404');
        return;
    }

    if (Array.isArray(teams) && teams.length === 1) {
        router.push(`/app/team/${teams[0].slug}`);
        return;
    }

    return (
        <DefaultLayout metaData={metaData}>
            <div className='main-content'>
                <div className='row'>
                    <TopSection title={'Select team page'} />
                </div>
                <div className="row">
                    {teams.map(team => {
                        const { name, slug, cover, members } = team;
                        return (
                            <div key={slug} className="col-12 col-md-6 col-xl-4">
                                <Card title={name} href={`/app/team/${slug}`} image={cover} members={members} />
                            </div>
                        );
                    })}

                    <div key={'add-new'} className="col-12 col-md-6 col-xl-4">
                        <Card title={'Add new team'} href={'/app/team/add-new'} image={'https://via.placeholder.com/600x451.png?text=Add new team'} />
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default AppHome;
import { useSWR, fetcher } from 'foundation/ApiRequest';

export const useTeamBySlug = (slug) => {
    const { data, error } = useSWR(slug ? `/api/team/${slug}` : null, slug ? fetcher : null);

    return {
        team: data,
        isLoading: !error && !data,
        isError: error
    };
};

export const useMyTeams = () => {
    const { data, error } = useSWR(`/api/me/teams`, fetcher);

    return {
        teams: data,
        isLoading: !error && !data,
        isError: error
    };
};